import { ModelData } from '.'

const processJsonData = (jsonData: any, stdParam = 1): any => {
  const jsonDataCopy = { ...jsonData }
  let sum = 0
  let count = 0
  const keys = Object.keys(jsonDataCopy)

  keys.forEach((key) => {
    const value = jsonDataCopy[key]
    if (value > 0) {
      sum += value
      count++
    }
  })

  if (count === 0) {
    return
  }

  const mean = sum / count
  let varianceSum = 0
  keys.forEach((key) => {
    const value = jsonDataCopy[key]
    if (value > 0) {
      // eslint-disable-next-line no-restricted-properties
      varianceSum += Math.pow(value - mean, 2)
    }
  })

  const variance = varianceSum / count
  const stdDev = Math.sqrt(variance)
  const lowerBound = -(mean - stdParam * stdDev)

  keys.forEach((key) => {
    const value = jsonDataCopy[key]
    if (value < lowerBound) {
      jsonDataCopy[key] = 0
    }
  })

  // eslint-disable-next-line consistent-return
  return jsonDataCopy
}

const midiNoteNumberToNoteName = (midiNoteNumber: any): any => {
  const noteNames = [
    'C',
    'C#',
    'D',
    'D#',
    'E',
    'F',
    'F#',
    'G',
    'G#',
    'A',
    'A#',
    'B'
  ]
  const octave = Math.floor(midiNoteNumber / 12) - 1
  const noteName = noteNames[midiNoteNumber % 12]
  return `${noteName}${octave}`
}

export const getNonZeroKeys = (obj: any): any => {
  const filteredObj = processJsonData(obj)
  const keys = Object.keys(filteredObj)
  // console.log(obj, filteredObj)
  const nonZeroKeys = keys.filter((key) => filteredObj[key] !== 0)
  return nonZeroKeys
}

export const getHighestNote = (pitch: Record<string, number>): string => {
  return midiNoteNumberToNoteName(Math.max(...getNonZeroKeys(pitch)))
}

export const getLowestAndHighestKeys = (obj: any): any => {
  const lowestKey = obj[0]
  const highestKey = obj[obj.length - 1]

  return {
    lowestKey,
    highestKey,
    lowestNote: midiNoteNumberToNoteName(lowestKey),
    highestNote: midiNoteNumberToNoteName(highestKey)
  }
}

export const getPitchProfile = (profile: ModelData['profile']): string => {
  if (!profile) {
    return ''
  }

  return `${profile.lowestNote} - ${profile.highestNote} | ${profile.label}`
}
