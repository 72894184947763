export var animation = {
  nm: 'play-anim',
  ddd: 0,
  h: 24,
  w: 24,
  meta: {
    g: 'LottieFiles Figma v59'
  },
  layers: [{
    ty: 4,
    nm: '3',
    sr: 1,
    st: 0,
    op: 37.18,
    ip: 0,
    hd: false,
    ddd: 0,
    bm: 0,
    hasMask: false,
    ao: 0,
    ks: {
      a: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 4],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 7],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 4],
          t: 24
        }, {
          s: [0, 4],
          t: 36
        }]
      },
      s: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 24
        }, {
          s: [100, 100],
          t: 36
        }]
      },
      sk: {
        a: 0,
        k: 0
      },
      p: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [12, 12],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [12, 12],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [12, 12],
          t: 24
        }, {
          s: [12, 12],
          t: 36
        }]
      },
      r: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 24
        }, {
          s: [0],
          t: 36
        }]
      },
      sa: {
        a: 0,
        k: 0
      },
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    },
    shapes: [{
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 14], [0, 14.75], [0.75, 14], [-0.75, 14]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 14], [0.75, 14], [0.75, 0], [-0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'fl',
      bm: 0,
      hd: false,
      nm: '',
      c: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 24
        }, {
          s: [1, 1, 1],
          t: 36
        }]
      },
      r: 1,
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    }],
    ind: 1
  }, {
    ty: 4,
    nm: '4',
    sr: 1,
    st: 0,
    op: 37.18,
    ip: 0,
    hd: false,
    ddd: 0,
    bm: 0,
    hasMask: false,
    ao: 0,
    ks: {
      a: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 3],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 2],
          t: 24
        }, {
          s: [0, 1],
          t: 36
        }]
      },
      s: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 24
        }, {
          s: [100, 100],
          t: 36
        }]
      },
      sk: {
        a: 0,
        k: 0
      },
      p: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [16, 12],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [16, 12],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [16, 12],
          t: 24
        }, {
          s: [16, 12],
          t: 36
        }]
      },
      r: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 24
        }, {
          s: [0],
          t: 36
        }]
      },
      sa: {
        a: 0,
        k: 0
      },
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    },
    shapes: [{
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 2], [0, 2.75], [0.75, 2], [-0.75, 2]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 6], [0, 6.75], [0.75, 6], [-0.75, 6]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 2], [0, 2.75], [0.75, 2], [-0.75, 2]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 2], [0.75, 2], [0.75, 0], [-0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 6], [0.75, 6], [0.75, 0], [-0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 2], [0.75, 2], [0.75, 0], [-0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'fl',
      bm: 0,
      hd: false,
      nm: '',
      c: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 24
        }, {
          s: [1, 1, 1],
          t: 36
        }]
      },
      r: 1,
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    }],
    ind: 2
  }, {
    ty: 4,
    nm: '2',
    sr: 1,
    st: 0,
    op: 37.18,
    ip: 0,
    hd: false,
    ddd: 0,
    bm: 0,
    hasMask: false,
    ao: 0,
    ks: {
      a: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 2],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 3],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 2],
          t: 24
        }, {
          s: [0, 2],
          t: 36
        }]
      },
      s: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 24
        }, {
          s: [100, 100],
          t: 36
        }]
      },
      sk: {
        a: 0,
        k: 0
      },
      p: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [8, 12],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [8, 12],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [8, 12],
          t: 24
        }, {
          s: [8, 12],
          t: 36
        }]
      },
      r: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 24
        }, {
          s: [0],
          t: 36
        }]
      },
      sa: {
        a: 0,
        k: 0
      },
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    },
    shapes: [{
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 6], [0, 6.75], [0.75, 6], [-0.75, 6]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 6], [0.75, 6], [0.75, 0], [-0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'fl',
      bm: 0,
      hd: false,
      nm: '',
      c: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 24
        }, {
          s: [1, 1, 1],
          t: 36
        }]
      },
      r: 1,
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    }],
    ind: 3
  }, {
    ty: 4,
    nm: '1',
    sr: 1,
    st: 0,
    op: 37.18,
    ip: 0,
    hd: false,
    ddd: 0,
    bm: 0,
    hasMask: false,
    ao: 0,
    ks: {
      a: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 4],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 2],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 1],
          t: 24
        }, {
          s: [0, 4],
          t: 36
        }]
      },
      s: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 24
        }, {
          s: [100, 100],
          t: 36
        }]
      },
      sk: {
        a: 0,
        k: 0
      },
      p: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [4, 12],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [4, 12],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [4, 12],
          t: 24
        }, {
          s: [4, 12],
          t: 36
        }]
      },
      r: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 24
        }, {
          s: [0],
          t: 36
        }]
      },
      sa: {
        a: 0,
        k: 0
      },
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    },
    shapes: [{
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 2], [0, 2.75], [0.75, 2], [-0.75, 2]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 2], [0.75, 2], [0.75, 0], [-0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'fl',
      bm: 0,
      hd: false,
      nm: '',
      c: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 24
        }, {
          s: [1, 1, 1],
          t: 36
        }]
      },
      r: 1,
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    }],
    ind: 4
  }, {
    ty: 4,
    nm: '5',
    sr: 1,
    st: 0,
    op: 37.18,
    ip: 0,
    hd: false,
    ddd: 0,
    bm: 0,
    hasMask: false,
    ao: 0,
    ks: {
      a: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 4],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 2],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0, 1],
          t: 24
        }, {
          s: [0, 4],
          t: 36
        }]
      },
      s: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100, 100],
          t: 24
        }, {
          s: [100, 100],
          t: 36
        }]
      },
      sk: {
        a: 0,
        k: 0
      },
      p: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [20, 12],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [20, 12],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [20, 12],
          t: 24
        }, {
          s: [20, 12],
          t: 36
        }]
      },
      r: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [0],
          t: 24
        }, {
          s: [0],
          t: 36
        }]
      },
      sa: {
        a: 0,
        k: 0
      },
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    },
    shapes: [{
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0.41, 0], [0, -0.41], [0, 0]],
            o: [[0, -0.41], [-0.41, 0], [0, 0], [0, 0]],
            v: [[0.75, 0], [0, -0.75], [-0.75, 0], [0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 4], [0, 4.75], [0.75, 4], [-0.75, 4]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 2], [0, 2.75], [0.75, 2], [-0.75, 2]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [-0.41, 0], [0, 0.41], [0, 0]],
            o: [[0, 0.41], [0.41, 0], [0, 0], [0, 0]],
            v: [[-0.75, 8], [0, 8.75], [0.75, 8], [-0.75, 8]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'sh',
      bm: 0,
      hd: false,
      nm: '',
      d: 1,
      ks: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 4], [0.75, 4], [0.75, 0], [-0.75, 0]]
          }],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 2], [0.75, 2], [0.75, 0], [-0.75, 0]]
          }],
          t: 24
        }, {
          s: [{
            c: true,
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
            v: [[-0.75, 0], [-0.75, 8], [0.75, 8], [0.75, 0], [-0.75, 0]]
          }],
          t: 36
        }]
      }
    }, {
      ty: 'fl',
      bm: 0,
      hd: false,
      nm: '',
      c: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [1, 1, 1],
          t: 24
        }, {
          s: [1, 1, 1],
          t: 36
        }]
      },
      r: 1,
      o: {
        a: 1,
        k: [{
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 0
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 12
        }, {
          o: {
            x: 0.33,
            y: 1
          },
          i: {
            x: 0.68,
            y: 1
          },
          s: [100],
          t: 24
        }, {
          s: [100],
          t: 36
        }]
      }
    }],
    ind: 5
  }],
  v: '5.7.0',
  fr: 60,
  op: 36.18,
  ip: 0,
  assets: []
};