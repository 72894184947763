import { create } from 'zustand'
import { config } from '../../config'
import { Moises } from '../../lib/graphql'

export type Subscription = {
  isPremium: boolean
  isPro: boolean
  hasUsedStripeTrial: boolean
  availableCredits: number
}

export type User = {
  id: string
  name?: string
  email?: string
  subscription?: Subscription
  featureFlags?: {
    [key: string]: string | number | boolean
  }
}

export interface UserStore {
  user: null | User
  userToken: null | string
  getUser(): User | null
  setUser(user: User): void
  setUserToken(userToken: string): void
  loadUserData(token: string, refresh?: boolean): Promise<string>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useUserStore = create<UserStore>((set, get) => ({
  // Common state
  user: null,
  userToken: null,
  // Generic actions
  getUser: () => get().user,
  setUser: (user: User) => set({ user }),
  setUserToken: (userToken: string) => set({ userToken }),
  loadUserData: async (token: string, refresh = false) => {
    if ((!refresh && get().user) || !token) {
      return token
    }

    // Load user data
    MoisesCLI.auth(token)
    const user = await MoisesCLI.getUser()

    if (user) {
      get().setUser(user)
    }

    get().setUserToken(token)
    return token
  }
}))
