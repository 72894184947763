import { Config } from './types'

export const stage: Config = {
  env: 'stage',
  cookieName: 'moisesCookieStage',
  sentry: {
    dsn: 'https://71a2310afdc05fb18a2b8b4a54e0e85a@o418792.ingest.us.sentry.io/4507245758119936'
  },
  urlDownload: {
    endpoint: 'https://url-download-stage.moises.ai'
  },
  graphql: {
    endpoint: 'https://api-stage.moises.ai/graphql'
  },
  api: {
    endpoint: 'https://api-stage.moises.ai',
    balance: {
      d1: 'https://d1-stage.moises.ai',
      d2: 'https://d2-stage.moises.ai',
      d3: 'https://d3-stage.moises.ai'
    }
  },
  transcode: {
    endpoint: 'https://service-transcode-rwvaxff72a-wl.a.run.app'
  },
  firebase: {
    recaptchaToken: '6LfkalcoAAAAAM6m-fvmSV9U_AfOZa1KCKZOlUS9',
    vapidKey:
      'BK-Cci0WPB4Md7DrFh_ay6_hjTS86JISLYe8wpmHIfjkasYLndMILp5w5MMec71z8XqWCdcNzJ0BDlTU3TYmtyg',
    auth: {
      apiKey: 'AIzaSyClKhEICctB11XUWd0GyO50Zu7aVWpwp-M',
      authDomain: 'moises-stage.firebaseapp.com',
      databaseURL: 'https://moises-stage.firebaseio.com',
      projectId: 'moises-stage',
      storageBucket: 'moises-stage.appspot.com',
      messagingSenderId: '941298360423',
      measurementId: 'G-JF0FT92KLY',
      appId: '1:941298360423:web:eae58942fc585849a8f4c2'
    }
  }
}
