import axios from 'axios'
import { gql } from 'graphql-request'
import { Task as TaskType } from '../../../types/task.types'

interface TaskCli {
  uploadUrl(data: { url: any }): Promise<any>
  uploadFile(data: { file: string }): Promise<any>
  createTask(data: { taskFile: any; operations: any }): Promise<any>
  fetchTask(i: { taskId: string }): Promise<TaskType>
  fetchTasks(i: {
    offset: number
    limit: number
    searchQuery: string
    operationName?: string[]
    hideDemos?: boolean
  }): Promise<any>
  updateTrack(data: { trackId: string; name: string }): Promise<any>
  deleteTrack(data: { trackId: string }): Promise<any>
  addOperationsToTask(data: { trackId: any; operations: any }): Promise<any>
  deleteVoiceTransferOperation(data: { ids: string[] }): Promise<boolean>
  updateTrackMetadata(data: { trackId: string; value: any }): Promise<any>
  eventTrack(data: { data: { event: string; payload: any } }): Promise<any>
}

const Task = (graphQL: any): TaskCli => {
  const uploadFile = async ({ file }: { file: any }): Promise<any> => {
    const query = gql`
            mutation {
                uploadFile(input:"${file.name}", type:FILESYSTEM ){
                    tempLocation
                    signedUrl
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    await axios({
      url: result.uploadFile.signedUrl,
      method: 'PUT',
      // headers: { 'Content-Type': file.type },
      data: file
    })

    delete result.uploadFile.signedUrl

    result.uploadFile.provider = 'FILESYSTEM'
    return result.uploadFile
  }

  const uploadUrl = async ({ url }: { url: string }): Promise<any> => {
    const query = gql`
            mutation {
                uploadFile(input:"${url}", type:URL){
                    tempLocation
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    result.uploadFile.provider = 'URL'
    return result.uploadFile
  }

  const createTask = async ({
    taskFile,
    operations
  }: {
    taskFile: any
    operations: any
  }): Promise<any> => {
    const opsGql = operations.map((op: any) => {
      return `{ name: ${op.name} params: ${JSON.stringify(op.params)}}`
    })

    const mutation = gql`
            mutation {
                createTask(
                    file: {
                    provider: ${taskFile.provider}
                    tempLocation: "${taskFile.tempLocation}"
                    name: "${taskFile.name}"
                    input: "${taskFile.input}"
                    }
                    operations: [${opsGql}]
                )
            }
        `

    const unquotedQuery = mutation.replace(/"([^"]+)":/g, '$1:')

    const result = await graphQL({ query: unquotedQuery })

    return result.createTask
  }

  const fetchTasks = async ({
    offset,
    limit,
    searchQuery = '',
    operationName,
    hideDemos = false
  }: {
    offset: number
    limit: number
    searchQuery: string
    operationName?: string[]
    hideDemos?: boolean
  }): Promise<any> => {
    const variables = {
      pagination: {
        offset,
        limit
      },
      filters: {
        searchQuery,
        operationName,
        hideDemos
      }
    }

    const query = gql`
      query ($pagination: Pagination!, $filters: TrackFilter) {
        tracks(pagination: $pagination, filters: $filters) {
          totalCount
          edges {
            node {
              id
              isDemo
              file {
                name
                input
              }
              operations {
                id
                name
                status
                createdAt
                startedAt
                completedAt
                params
                result
              }
            }
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchTask = async ({
    taskId
  }: {
    taskId: string
  }): Promise<TaskType> => {
    const variables = {
      id: taskId
    }

    const query = gql`
      query ($id: String!) {
        track(id: $id) {
          id
          file {
            name
            input
            location
            duration
          }
          recommendedVoiceModels {
            id
            pitch
            accuracy
            compatible
          }
          metadata {
            id
            key
            value
            updatedAt
          }
          operations {
            id
            name
            status
            createdAt
            startedAt
            completedAt
            params
            current
            outdated
            outdatedReason
            updateInProgress
            isOwner
            result
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result.track
  }

  const updateTrack = async ({
    trackId,
    name
  }: {
    trackId: string
    name: string
  }): Promise<any> => {
    const variables = {
      id: trackId,
      data: {
        name
      }
    }
    const query = gql`
      mutation updateTrack($id: ID!, $data: UpdateTrackInput!) {
        updateTrack(id: $id, data: $data) {
          id
          name
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateTrackMetadata = async ({
    trackId,
    value
  }: {
    trackId: string
    value: any
  }): Promise<any> => {
    const variables = {
      trackId,
      value
    }

    const query = gql`
      mutation updateTrackMetadata($trackId: String!, $value: JSON!) {
        updateTrackMetadata(
          trackId: $trackId
          key: "voice-studio"
          value: $value
        )
      }
    `

    const result = await graphQL({ query, variables })
    return result
  }

  const deleteTrack = async ({
    trackId
  }: {
    trackId: string
  }): Promise<any> => {
    const variables = {
      id: trackId
    }
    const query = gql`
      mutation deleteTask($id: String!) {
        deleteTask(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const addOperationsToTask = async ({
    trackId,
    operations
  }: {
    trackId: string
    operations: any
  }): Promise<any> => {
    const variables = {
      trackId,
      operations
    }
    const query = gql`
      mutation ($trackId: ID!, $operations: [OperationInput!]!) {
        addOperationsToTrack(trackId: $trackId, operations: $operations)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const deleteVoiceTransferOperation = async ({
    ids
  }: {
    ids: string[]
  }): Promise<any> => {
    const variables = {
      ids
    }
    const query = gql`
      mutation ($ids: [String!]!) {
        deleteVoiceTransferOperation(ids: $ids)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const eventTrack = async ({
    data
  }: {
    data: {
      event: string
      payload: any
    }
  }): Promise<any> => {
    const variables = {
      data
    }
    const query = gql`
      mutation ($data: EventTrackInput!) {
        eventTrack(data: $data)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    uploadUrl,
    uploadFile,
    createTask,
    fetchTasks,
    fetchTask,
    updateTrack,
    deleteTrack,
    addOperationsToTask,
    deleteVoiceTransferOperation,
    updateTrackMetadata,
    eventTrack
  }
}

export default Task
