import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState, useEffect, useCallback } from 'react';
import Soundfont from 'soundfont-player';

var SoundfontProvider = function SoundfontProvider(_ref) {
  var instrumentName = _ref.instrumentName,
      hostname = _ref.hostname,
      _ref$format = _ref.format,
      format = _ref$format === void 0 ? 'mp3' : _ref$format,
      _ref$soundfont = _ref.soundfont,
      soundfont = _ref$soundfont === void 0 ? 'MusyngKite' : _ref$soundfont,
      audioContext = _ref.audioContext,
      render = _ref.render;

  var _useState = useState({}),
      activeAudioNodes = _useState[0],
      setActiveAudioNodes = _useState[1];

  var _useState2 = useState(null),
      instrument = _useState2[0],
      setInstrument = _useState2[1];

  var loadInstrument = useCallback(function (instrumentNameItem) {
    setInstrument(null);
    Soundfont.instrument(audioContext, instrumentNameItem, {
      format: format,
      soundfont: soundfont,
      nameToUrl: function nameToUrl(name, soundfontItem, formatItem) {
        return "".concat(hostname, "/").concat(soundfontItem, "/").concat(name, "-").concat(formatItem, ".js");
      }
    }).then(function (instrumentItem) {
      setInstrument(instrumentItem);
    });
  }, [audioContext, format, soundfont, hostname]);
  useEffect(function () {
    loadInstrument(instrumentName);
  }, [instrumentName, loadInstrument]);
  var playNote = useCallback(function (midiNumber) {
    audioContext.resume().then(function () {
      var audioNode = instrument === null || instrument === void 0 ? void 0 : instrument.play(midiNumber);
      setActiveAudioNodes(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, midiNumber, audioNode));
      });
    });
  }, [instrument, audioContext]);
  var stopNote = useCallback(function (midiNumber) {
    audioContext.resume().then(function () {
      if (!activeAudioNodes[midiNumber]) {
        return;
      }

      var audioNode = activeAudioNodes[midiNumber];
      audioNode.stop();
      setActiveAudioNodes(function (prev) {
        return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, midiNumber, null));
      });
    });
  }, [activeAudioNodes, audioContext]);
  var stopAllNotes = useCallback(function () {
    audioContext.resume().then(function () {
      Object.values(activeAudioNodes).forEach(function (node) {
        if (node) node.stop();
      });
      setActiveAudioNodes({});
    });
  }, [activeAudioNodes, audioContext]);
  return render({
    isLoading: !instrument,
    playNote: playNote,
    stopNote: stopNote,
    stopAllNotes: stopAllNotes
  });
};

export default SoundfontProvider;