import { Alert } from 'interface'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useToast } from '../../store/toast'
import styles from './styles.module.scss'

export const ToastContainer: React.FC = () => {
  const { list } = useToast()

  return (
    <ul className={styles.list}>
      <TransitionGroup>
        {list.map((alert) => (
          <CSSTransition key={alert.id} timeout={500} classNames="alert-toast">
            <li className={styles.item}>
              <Alert {...alert} />
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </ul>
  )
}
