import { Config } from './types'

export const production: Config = {
  env: 'production',
  cookieName: 'moisesCookie',
  sentry: {
    dsn: 'https://71a2310afdc05fb18a2b8b4a54e0e85a@o418792.ingest.us.sentry.io/4507245758119936'
  },
  urlDownload: {
    endpoint: 'https://url-download.moises.ai'
  },
  graphql: {
    endpoint: 'https://api.moises.ai/graphql'
  },
  api: {
    endpoint: 'https://api.moises.ai',
    balance: {
      d1: 'https://d1.moises.ai',
      d2: 'https://d2.moises.ai',
      d3: 'https://d3.moises.ai'
    }
  },
  transcode: {
    endpoint: 'https://service-transcode-6fvc7ord4a-wl.a.run.app'
  },
  firebase: {
    recaptchaToken: '6LcYezglAAAAACOiXOf8mCU2uYxvi6fQtz6M4X5U',
    vapidKey:
      'BAYj-jIHz9wCfj964atRUu0sc-pZmsVpPk-tmpSFxYn9LHgpnsl86x8oVW0mjA8Hi80vSPXoH9NZzBYdfxUqcTM',
    auth: {
      apiKey: 'AIzaSyDWcFRZcUnN5EPNNA7jrcuS3HlIvMqtuCs',
      authDomain: 'app.moises.ai',
      databaseURL: 'https://spleeter.firebaseio.com',
      projectId: 'spleeter',
      storageBucket: 'spleeter.appspot.com',
      messagingSenderId: '731360694588',
      appId: '1:731360694588:web:4da0f700a5193a834ace39',
      measurementId: 'G-VCCHPVZC99'
    }
  }
}
