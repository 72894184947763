import { useEffect } from 'react';
export var useOnClickOutside = function useOnClickOutside(ref, handler) {
  useEffect(function () {
    var samePageListener = function samePageListener(event) {
      var el = ref === null || ref === void 0 ? void 0 : ref.current;

      if (!el || el.contains(event.target)) {
        return;
      }

      handler(event);
    }; // Clicks inside the iframe doesn't trigger mousedown and
    //  touchstart events on the document


    var iframeListener = function iframeListener() {
      // setTimeout is an workaround for Firefox
      // https://gist.github.com/jaydson/1780598?
      // permalink_comment_id=2609301#gistcomment-2609301
      setTimeout(function () {
        if (document.activeElement instanceof HTMLIFrameElement) {
          handler(null);
        }
      }, 0);
    };

    document.addEventListener('mousedown', samePageListener);
    document.addEventListener('touchstart', samePageListener);
    window.addEventListener('blur', iframeListener);
    return function () {
      document.removeEventListener('mousedown', samePageListener);
      document.removeEventListener('touchstart', samePageListener);
      window.removeEventListener('blur', iframeListener);
    };
  }, [ref, handler]);
};