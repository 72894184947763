import { useCallback, useEffect } from 'react'
import { useEffectOnce, useInterval } from 'react-use'
import { getCookie } from 'cookies-next'
import { useUserStore } from '../../../store/user'

const parseJwt = (token: string): any => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const useRefreshToken = ({
  cookieName
}: {
  cookieName: string
}): void => {
  const { setUserToken, userToken } = useUserStore()

  useEffect(() => {
    if (!userToken) return

    const payload = parseJwt(userToken)
    if (payload && payload.exp) {
      const expireIn = payload.exp * 1000 - Date.now()
      setTimeout(() => {
        window.parent.postMessage({ type: 'token-expired' }, '*')
      }, expireIn - 10000)
    }
  }, [userToken])

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event?.data?.type === 'refresh-token' && event?.data?.payload) {
        setUserToken(event.data.payload)
      }
    },
    [setUserToken]
  )

  useInterval(() => {
    const cookieValue = getCookie(cookieName)
    if (!cookieValue) return

    const payload = parseJwt(cookieValue)
    if (!payload || !payload.authorization) return

    setUserToken(payload.authorization)
  }, 1000 * 60)

  useEffectOnce(() => {
    if (global.window !== undefined) {
      window.addEventListener('message', handleMessage)
    }
  })
}
