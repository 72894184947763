// import Script from 'next/script'

export const Scripts: React.FC = () => (
  <>
    {/* <Script
      id="rudderstack-init"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: ``
      }}
    /> */}
  </>
)
