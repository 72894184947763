export const locales = [
  {
    id: 'en',
    englishLabel: 'English',
    label: 'English',
    allCompatibleLanguageISO: [
      'en', // English
      'en-as', // English (American Samoa)
      'en-ai', // English (Anguilla)
      'en-ag', // English (Antigua & Barbuda)
      'en-au', // English (Australia)
      'en-bs', // English (Bahamas)
      'en-bb', // English (Barbados)
      'en-be', // English (Belgium)
      'en-bz', // English (Belize)
      'en-bm', // English (Bermuda)
      'en-bw', // English (Botswana)
      'en-io', // English (British Indian Ocean Territory)
      'en-vg', // English (British Virgin Islands)
      'en-cm', // English (Cameroon)
      'en-ca', // English (Canada)
      'en-ky', // English (Cayman Islands)
      'en-cx', // English (Christmas Island)
      'en-cc', // English (Cocos (Keeling) Islands)
      'en-ck', // English (Cook Islands)
      'en-dg', // English (Diego Garcia)
      'en-dm', // English (Dominica)
      'en-er', // English (Eritrea)
      'en-fk', // English (Falkland Islands)
      'en-fj', // English (Fiji)
      'en-gm', // English (Gambia)
      'en-gh', // English (Ghana)
      'en-gi', // English (Gibraltar)
      'en-gd', // English (Grenada)
      'en-gu', // English (Guam)
      'en-gg', // English (Guernsey)
      'en-gy', // English (Guyana)
      'en-hk', // English (Hong Kong SAR China)
      'en-in', // English (India)
      'en-ie', // English (Ireland)
      'en-im', // English (Isle of Man)
      'en-jm', // English (Jamaica)
      'en-je', // English (Jersey)
      'en-ke', // English (Kenya)
      'en-ki', // English (Kiribati)
      'en-ls', // English (Lesotho)
      'en-lr', // English (Liberia)
      'en-mo', // English (Macau SAR China)
      'en-mg', // English (Madagascar)
      'en-mw', // English (Malawi)
      'en-my', // English (Malaysia)
      'en-mt', // English (Malta)
      'en-mh', // English (Marshall Islands)
      'en-mu', // English (Mauritius)
      'en-fm', // English (Micronesia)
      'en-ms', // English (Montserrat)
      'en-na', // English (Namibia)
      'en-nr', // English (Nauru)
      'en-nz', // English (New Zealand)
      'en-ng', // English (Nigeria)
      'en-nu', // English (Niue)
      'en-nf', // English (Norfolk Island)
      'en-mp', // English (Northern Mariana Islands)
      'en-pk', // English (Pakistan)
      'en-pw', // English (Palau)
      'en-pg', // English (Papua New Guinea)
      'en-ph', // English (Philippines)
      'en-pn', // English (Pitcairn Islands)
      'en-pr', // English (Puerto Rico)
      'en-rw', // English (Rwanda)
      'en-ws', // English (Samoa)
      'en-sc', // English (Seychelles)
      'en-sl', // English (Sierra Leone)
      'en-sg', // English (Singapore)
      'en-sx', // English (Sint Maarten)
      'en-sb', // English (Solomon Islands)
      'en-za', // English (South Africa)
      'en-ss', // English (South Sudan)
      'en-sh', // English (St. Helena)
      'en-kn', // English (St. Kitts & Nevis)
      'en-lc', // English (St. Lucia)
      'en-vc', // English (St. Vincent & Grenadines)
      'en-sd', // English (Sudan)
      'en-sz', // English (Swaziland)
      'en-tz', // English (Tanzania)
      'en-tk', // English (Tokelau)
      'en-to', // English (Tonga)
      'en-tt', // English (Trinidad & Tobago)
      'en-tc', // English (Turks & Caicos Islands)
      'en-tv', // English (Tuvalu)
      'en-um', // English (U.S. Outlying Islands)
      'en-vi', // English (U.S. Virgin Islands)
      'en-ug', // English (Uganda)
      'en-gb', // English (United Kingdom)
      'en-us', // English (United States)
      'en-vu', // English (Vanuatu)
      'en-zm', // English (Zambia)
      'en-zw' // English (Zimbabwe)
    ]
  },
  {
    id: 'es',
    englishLabel: 'Spanish',
    label: 'Español',
    allCompatibleLanguageISO: [
      'es', // Spanish
      'es-ar', // Spanish (Argentina)
      'es-bo', // Spanish (Bolivia)
      'es-ic', // Spanish (Canary Islands)
      'es-ea', // Spanish (Ceuta & Melilla)
      'es-cl', // Spanish (Chile)
      'es-co', // Spanish (Colombia)
      'es-cr', // Spanish (Costa Rica)
      'es-cu', // Spanish (Cuba)
      'es-do', // Spanish (Dominican Republic)
      'es-ec', // Spanish (Ecuador)
      'es-sv', // Spanish (El Salvador)
      'es-gq', // Spanish (Equatorial Guinea)
      'es-gt', // Spanish (Guatemala)
      'es-hn', // Spanish (Honduras)
      'es-mx', // Spanish (Mexico)
      'es-ni', // Spanish (Nicaragua)
      'es-pa', // Spanish (Panama)
      'es-py', // Spanish (Paraguay)
      'es-pe', // Spanish (Peru)
      'es-ph', // Spanish (Philippines)
      'es-pr', // Spanish (Puerto Rico)
      'es-es', // Spanish (Spain)
      'es-us', // Spanish (United States)
      'es-uy', // Spanish (Uruguay)
      'es-ve', // Spanish (Venezuela)
      'es-419', // Latin America and Caribbean
      'es-xl' // Latin America (apple devices)
    ]
  },
  {
    id: 'pt_BR',
    englishLabel: 'Portuguese',
    label: 'Português',
    allCompatibleLanguageISO: [
      'pt', // Portuguese"
      'pt-ao', // Portuguese (Angola)
      'pt-br', // Portuguese (Brazil)
      'pt-cv', // Portuguese (Cape Verde)
      'pt-gw', // Portuguese (Guinea-Bissau)
      'pt-mo', // Portuguese (Macau SAR China)
      'pt-mz', // Portuguese (Mozambique)
      'pt-pt', // Portuguese (Portugal)
      'pt-st', // Portuguese (S\u00e3o Tom\u00e9 & Pr\u00edncipe)
      'pt-tl' // Portuguese (Timor-Leste)
    ]
  },
  {
    id: 'ar',
    englishLabel: 'Arabic',
    label: 'العربية',
    allCompatibleLanguageISO: [
      'ar', // Arabic"
      'ar-dz', // Arabic (Algeria)
      'ar-bh', // Arabic (Bahrain)
      'ar-td', // Arabic (Chad)
      'ar-km', // Arabic (Comoros)
      'ar-dj', // Arabic (Djibouti)
      'ar-eg', // Arabic (Egypt)
      'ar-er', // Arabic (Eritrea)
      'ar-iq', // Arabic (Iraq)
      'ar-il', // Arabic (Israel)
      'ar-jo', // Arabic (Jordan)
      'ar-kw', // Arabic (Kuwait)
      'ar-lb', // Arabic (Lebanon)
      'ar-ly', // Arabic (Libya)
      'ar-mr', // Arabic (Mauritania)
      'ar-ma', // Arabic (Morocco)
      'ar-om', // Arabic (Oman)
      'ar-ps', // Arabic (Palestinian Territories)
      'ar-qa', // Arabic (Qatar)
      'ar-sa', // Arabic (Saudi Arabia)
      'ar-so', // Arabic (Somalia)
      'ar-ss', // Arabic (South Sudan)
      'ar-sd', // Arabic (Sudan)
      'ar-sy', // Arabic (Syria)
      'ar-tn', // Arabic (Tunisia)
      'ar-ae', // Arabic (United Arab Emirates)
      'ar-eh', // Arabic (Western Sahara)
      'ar-ye' // Arabic (Yemen)
    ]
  },

  {
    id: 'nl',
    englishLabel: 'Dutch',
    label: 'Dutch',
    allCompatibleLanguageISO: [
      'nl', // Dutch"
      'nl-aw', // Dutch (Aruba)
      'nl-be', // Dutch (Belgium)
      'nl-bq', // Dutch (Caribbean Netherlands)
      'nl-cw', // Dutch (Cura\u00e7ao)
      'nl-nl', // Dutch (Netherlands)
      'nl-sx', // Dutch (Sint Maarten)
      'nl-sr' // Dutch (Suriname)
    ]
  },
  {
    id: 'fr',
    englishLabel: 'French',
    label: 'Français',
    allCompatibleLanguageISO: [
      'fr', // French
      'fr-dz', // French (Algeria)
      'fr-be', // French (Belgium)
      'fr-bj', // French (Benin)
      'fr-bf', // French (Burkina Faso)
      'fr-bi', // French (Burundi)
      'fr-cm', // French (Cameroon)
      'fr-ca', // French (Canada)
      'fr-cf', // French (Central African Republic)
      'fr-td', // French (Chad)
      'fr-km', // French (Comoros)
      'fr-cg', // French (Congo - Brazzaville)
      'fr-cd', // French (Congo - Kinshasa)
      'fr-ci', // French (C\u00f4te d\u2019Ivoire)
      'fr-dj', // French (Djibouti)
      'fr-gq', // French (Equatorial Guinea)
      'fr-fr', // French (France)
      'fr-gf', // French (French Guiana)
      'fr-pf', // French (French Polynesia)
      'fr-ga', // French (Gabon)
      'fr-gp', // French (Guadeloupe)
      'fr-gn', // French (Guinea)
      'fr-ht', // French (Haiti)
      'fr-lu', // French (Luxembourg)
      'fr-mg', // French (Madagascar)
      'fr-ml', // French (Mali)
      'fr-mq', // French (Martinique)
      'fr-mr', // French (Mauritania)
      'fr-mu', // French (Mauritius)
      'fr-yt', // French (Mayotte)
      'fr-mc', // French (Monaco)
      'fr-ma', // French (Morocco)
      'fr-nc', // French (New Caledonia)
      'fr-ne', // French (Niger)
      'fr-re', // French (R\u00e9union)
      'fr-rw', // French (Rwanda)
      'fr-sn', // French (Senegal)
      'fr-sc', // French (Seychelles)
      'fr-bl', // French (St. Barth\u00e9lemy)
      'fr-mf', // French (St. Martin)
      'fr-pm', // French (St. Pierre & Miquelon)
      'fr-ch', // French (Switzerland)
      'fr-sy', // French (Syria)
      'fr-tg', // French (Togo)
      'fr-tn', // French (Tunisia)
      'fr-vu', // French (Vanuatu)
      'fr-wf' // French (Wallis & Futuna)
    ]
  },
  {
    id: 'de',
    englishLabel: 'German',
    label: 'Deutsch',
    allCompatibleLanguageISO: [
      'de', // German
      'de-at', // German (Austria)
      'de-be', // German (Belgium)
      'de-de', // German (Germany)
      'de-li', // German (Liechtenstein)
      'de-lu', // German (Luxembourg)
      'de-ch' // German (Switzerland)
    ]
  },
  {
    id: 'it',
    englishLabel: 'Italian',
    label: 'Italiano',
    allCompatibleLanguageISO: [
      'it', // Italian
      'it-it', // Italian (Italy)
      'it-sm', // Italian (San Marino)
      'it-ch' // Italian (Switzerland)
    ]
  },
  {
    id: 'pl',
    englishLabel: 'Polish',
    label: 'Polski',
    allCompatibleLanguageISO: ['pl', 'pl-pl']
  },
  {
    id: 'ru',
    englishLabel: 'Russian',
    label: 'Русский',
    allCompatibleLanguageISO: [
      'ru', // Russian
      'ru-by', // Russian (Belarus)
      'ru-kz', // Russian (Kazakhstan)
      'ru-kg', // Russian (Kyrgyzstan)
      'ru-md', // Russian (Moldova)
      'ru-ru', // Russian (Russia)
      'ru-ua' // Russian (Ukraine)
    ]
  },
  {
    id: 'sv',
    englishLabel: 'Swedish',
    label: 'svenska',
    allCompatibleLanguageISO: [
      'sv', // Swedish
      'sv-ax', // Swedish (\u00c5land Islands)
      'sv-fi', // Swedish (Finland)
      'sv-se' // Swedish (Sweden)
    ]
  },
  {
    id: 'zh_CN',
    englishLabel: 'Chinese Simplified',
    label: '简体中文',
    allCompatibleLanguageISO: [
      'zh', // Chinese
      'zh-cn', // Chinese (China)
      'zh-sg', // Chinese (Singapore)
      'zh-hans-cn', // Chinese (Simplified, China)
      'zh-hans-hk', // Chinese (Simplified, Hong Kong SAR China)
      'zh-hans-mo', // Chinese (Simplified, Macau SAR China)
      'zh-hans-sg', // Chinese (Simplified, Singapore)
      'zh-hans' // Chinese (Simplified)
    ]
  },
  {
    id: 'zh_TW',
    englishLabel: 'Chinese Traditional',
    label: '繁體中文',
    allCompatibleLanguageISO: [
      'zh-tw', // Chinese (Taiwan)",
      'zh-mo', // Chinese (Macau SAR China)",
      'zh-hk', // Chinese (Hong Kong SAR China)",
      'zh-hant-hk', // Chinese (Traditional, Hong Kong SAR China)",
      'zh-hant-mo', // Chinese (Traditional, Macau SAR China)",
      'zh-hant-tw', // Chinese (Traditional, Taiwan)",
      'zh-hant' // Chinese (Traditional)",
    ]
  },
  {
    id: 'hi_IN',
    englishLabel: 'Hindi (India)',
    label: 'हिंदी',
    allCompatibleLanguageISO: ['hi', 'hi-in']
  },
  {
    id: 'ja',
    englishLabel: 'Japanese',
    label: '日本語',
    allCompatibleLanguageISO: ['ja', 'ja-jp']
  },
  {
    id: 'ko',
    englishLabel: 'Korean',
    label: '한국어',
    allCompatibleLanguageISO: [
      'ko', // Korean
      'ko-kr', // Korean (South Korea)
      'ko-kp' // Korean (North Korea)
    ]
  },
  {
    id: 'ms',
    englishLabel: 'Malay',
    label: 'Bahasa Melayu',
    allCompatibleLanguageISO: [
      'ms', // Malay
      'ml', // Malayalam
      'ms-bn', // Malay (Brunei)
      'ms-latn-bn', // Malay (Latin, Brunei)
      'ms-latn-my', // Malay (Latin, Malaysia)
      'ms-latn-sg', // Malay (Latin, Singapore)
      'ms-latn', // Malay (Latin)
      'ms-my', // Malay (Malaysia)
      'ms-sg', // Malay (Singapore)
      'ml-in' // Malayalam (India)
    ]
  },
  {
    id: 'id',
    englishLabel: 'Indonesian',
    label: 'Bahasa Indonesia',
    allCompatibleLanguageISO: ['id', 'id-id']
  },
  {
    id: 'th',
    englishLabel: 'Thai',
    label: 'ไทย',
    allCompatibleLanguageISO: ['th', 'th-th']
  },
  {
    id: 'tr',
    englishLabel: 'Turkish',
    label: 'Türkçe',
    allCompatibleLanguageISO: [
      'tr', // Turkish
      'tr-cy', // Turkish (Cyprus)
      'tr-tr' // Turkish (Turkey)
    ]
  },
  {
    id: 'vi',
    englishLabel: 'Vietnamese',
    label: 'Tiếng Việt',
    allCompatibleLanguageISO: ['vi', 'vi-vn']
  },
  // variations code removed from the list https://github.com/fmcgoohan/wxWidgets/blob/222cf952f4618d4b907193359753e841a854702b/src/common/languageinfo.cpp#L188C34-L188C34
  {
    id: 'ca',
    englishLabel: 'Catalan',
    label: 'Catalan',
    allCompatibleLanguageISO: [
      "ca",
      "ca-AD",
      "ca-FR",
      "ca-IT",
      "ca-ES"
    ]
  },
  {
    id: 'fi',
    englishLabel: 'Finnish',
    label: 'Finnish',
    allCompatibleLanguageISO: [
      "fi",
    ]
  },
  {
    id: 'hu',
    englishLabel: 'Hungarian',
    label: 'Hungarian',
    allCompatibleLanguageISO: [
      "hu",
    ]
  },
  {
    id: 'cs',
    englishLabel: 'Czech',
    label: 'Czech',
    allCompatibleLanguageISO: [
      "cs",
    ]
  },
  {
    id: 'hr',
    englishLabel: 'Croatian',
    label: 'Croatian',
    allCompatibleLanguageISO: [
      "hr",
    ]
  },
  {
    id: 'da',
    englishLabel: 'Danish',
    label: 'Danish',
    allCompatibleLanguageISO: [
      "da",
    ]
  },
  {
    id: 'el',
    englishLabel: 'Greek',
    label: 'Greek',
    allCompatibleLanguageISO: [
      "el",
    ]
  },
  {
    id: 'he',
    englishLabel: 'Hebrew',
    label: 'Hebrew',
    allCompatibleLanguageISO: [
      "he",
    ]
  },
  {
    id: 'no',
    englishLabel: 'Norwegian',
    label: 'Norwegian',
    allCompatibleLanguageISO: [
      "no",
      "nb",
      "nn",
    ]
  },
  {
    id: 'ro',
    englishLabel: 'Romanian',
    label: 'Romanian',
    allCompatibleLanguageISO: [
      "ro",
    ]
  },
  {
    id: 'sk',
    englishLabel: 'Slovak',
    label: 'Slovak',
    allCompatibleLanguageISO: [
      "sk",
    ]
  },
  {
    id: 'uk',
    englishLabel: 'Ukrainian',
    label: 'Ukrainian',
    allCompatibleLanguageISO: [
      "uk",
    ]
  }
]
