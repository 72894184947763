import { i18n } from "@lingui/core";
import { locales as localesMap } from "./locales";

const locales = [
  "en",
  "es",
  "ar",
  "ca",
  "cs",
  "da",
  "de",
  "el",
  "es",
  "fi",
  "fr",
  "he",
  "hr",
  "hi_IN",
  "hu",
  "id",
  "it",
  "ja",
  "ko",
  "ms",
  "nl",
  "no",
  "pl",
  "pt",
  "pt_BR",
  "ro",
  "ru",
  "sk",
  "sv",
  "th",
  "tr",
  "uk",
  "vi",
  "zh_CN",
  "zh_TW",
];

export const defaultLocale = "en";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export function dynamicActivate(locale: string) {
  const localeShort = locale.split("-")[0];
  // get closest locale from the catalog array make sute that if locale is just the code, ex zh it will return zh_CN, or the first one from the array that is matches the base language without the region
  const getLocale = locales.find(
    (l) => l === locale || l.includes(localeShort)
  ) as string;

  const foundLocale = localesMap.find(({ allCompatibleLanguageISO }: any) =>
    allCompatibleLanguageISO.find(
      (langISO: any) => langISO === locale.toLowerCase()
    )
  );
  const localeFinal = foundLocale ? foundLocale.id : "en";

  const { messages } = require(`./languages/${localeFinal}/messages`);

  i18n.load(getLocale, messages);
  i18n.activate(getLocale);
}
