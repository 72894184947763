import { gql } from 'graphql-request'
import { User as UserType } from '../../../store/user'

interface UserCli {
  getUser(): Promise<UserType>
}

const User = (graphQL: any): UserCli => {
  const getUser = async (): Promise<UserType> => {
    const variables = {}
    const query = gql`
      query {
        user {
          id
          name
          email
          subscription {
            isPro
            isPremium
            hasUsedStripeTrial
            availableCredits
          }
          featureFlags
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.user
  }

  return {
    getUser
  }
}

export default User
