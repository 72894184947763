import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { create } from 'zustand'
import { v4 } from 'uuid'

interface AlertProps {
  id: string
  icon?: React.ReactNode | null
  type: string
  column?: boolean
  closable: boolean
  fixed?: boolean
  title?: string | null
  description?: string | null
  // buttonText?: string | null
  // onConfirm?(id: string): void
  onDismiss?(id: string): void
}

type ToastConfig = {
  type: string
  column?: boolean
  title?: string
  description?: string
  // buttonText?: string
  closable: boolean
  fixed?: boolean
  icon?: ReactElement | null
  // onConfirm?(): void
  onDismiss?(): void
}

interface Hook {
  list: AlertProps[]
  add(alert: ToastConfig): void
  onClearFixedToast(): void
}

interface Store {
  list: AlertProps[]
  setList: (list: AlertProps[]) => void
}

const useStore = create<Store>((set) => ({
  // Common state
  list: [],
  // Generic actions
  setList: (list: AlertProps[]) => set({ list })
}))

export const useToast = (): Hook => {
  const store = useStore((s) => s)
  const [MAX_LIST] = useState(4)
  const [list, { push, filter, removeAt }] = useList<AlertProps>([])

  useEffect(() => {
    const toastTimeoutIds: ReturnType<typeof setTimeout>[] = list.map((alert) =>
      setTimeout(() => {
        if (alert.onDismiss && !alert.fixed) {
          alert.onDismiss(alert.id)
        }
      }, 4000)
    )

    return () => {
      toastTimeoutIds.map((timeoutId) => clearTimeout(timeoutId))
    }
  }, [list])

  const add = useCallback(
    ({ onDismiss, onConfirm, ...alert }: any): string => {
      const id = v4()

      const handleDismiss = (alertId: string): void => {
        filter((item) => item.id !== alertId)

        if (onDismiss) {
          onDismiss(alertId)
        }
      }

      const handleConfirm = (alertId: string): void => {
        handleDismiss(alertId)

        if (onConfirm) {
          onConfirm(alert)
        }
      }

      push({
        ...alert,
        id,
        onConfirm: handleConfirm,
        onDismiss: handleDismiss
      })

      return id
    },
    [push, filter]
  )

  const onClearFixedToast = useCallback(() => {
    list.forEach((alert) => {
      if (alert.onDismiss && alert.fixed) {
        alert.onDismiss(alert.id)
      }
    })
  }, [list])

  useEffect(() => {
    if (list.length >= MAX_LIST) {
      removeAt(0)
    }
  }, [removeAt, list, MAX_LIST])

  useEffect(() => {
    store.setList(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, store.setList])

  return {
    list: store.list,
    add,
    onClearFixedToast
  }
}
