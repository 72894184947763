import { create } from 'zustand'
import { useEffectOnce } from 'react-use'
import { config } from '../../config'
import { Moises } from '../../lib/graphql'
import { useUserStore } from '../user'
import { getLowestAndHighestKeys, getNonZeroKeys } from './utils'

export type ModelData = {
  id: string
  name: string
  identifier: string
  description: string
  tags: string
  info?: string
  updatedAt: string
  private: boolean
  metadata: {
    cover: string
    image: string
    model: string
    avatar: string
    previewUrl: string
    pitchProfile: { [key: number]: number }
    originalSinger: string
    recordingStudio: string
    vocalRange: string
  }
  profile?: {
    highestKey?: string
    highestNote?: string
    lowestKey?: string
    lowestNote?: string
    label?: string
  }
}

interface Store {
  models: ModelData[]
  modelsByIdentifier: Record<string, ModelData>
  loading: boolean
  setLoading(loading: boolean): void
  setModels(models: ModelData[]): void
  loadPitchProfile(models: ModelData[]): void
  loadModels(token: string): void
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

const useStore = create<Store>((set, get) => ({
  // Common state
  models: [],
  modelsByIdentifier: {},
  loading: false,
  // Generic actions
  setLoading: (loading: boolean) => set({ loading }),
  setModels: (models: ModelData[]) => set({ models }),
  loadPitchProfile: async (models: ModelData[]) => {
    const list = models.map((item) => {
      const model = item

      if (model?.metadata?.pitchProfile) {
        const keys = getLowestAndHighestKeys(
          getNonZeroKeys(model.metadata.pitchProfile)
        )

        model.profile = {
          highestKey: keys.highestKey,
          highestNote: keys.highestNote,
          lowestKey: keys.lowestKey,
          lowestNote: keys.lowestNote,
          label: model.metadata.vocalRange
        }
      }

      return model
    })

    const modelsByIdentifier = list.reduce((acc, model) => {
      acc[model.identifier] = model
      return acc
    }, {} as Record<string, ModelData>)

    set({ models: list, modelsByIdentifier })
  },
  loadModels: async (token: string) => {
    if (get().models.length || !token || get().loading) {
      return
    }

    set({ loading: true })
    MoisesCLI.auth(token)
    const models = await MoisesCLI.getModels()

    if (models) {
      get().setModels(models)
      get().loadPitchProfile(models)
    }
    set({ loading: false })
  }
}))

interface Hook extends Store {}

export const useModelStore = (): Hook => {
  const store = useStore()
  const { userToken } = useUserStore()

  useEffectOnce(() => {
    store.loadModels(userToken as string)
  })

  return {
    ...store
  }
}
