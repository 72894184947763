import Head from 'next/head'
import { ReactElement } from 'react'
import { Scripts } from './scripts'
import { RootContainerType } from './types'

export const RootContainer: React.FC<RootContainerType> = ({
  children
}): ReactElement => (
  <>
    <Head>
      <link rel="icon" href="/favicon-32x32.png" />
      {/* <link rel="manifest" href="/manifest.json" /> */}
      <meta name="theme-color" content="#000000" />
      <meta name="robots" content="noindex, nofollow" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />

      <title>Voice Studio</title>
      {/* <meta
        name="description"
        content=""
      />
      <meta
        name="keywords"
        content=""
      />
      <meta
        property="og:title"
        content="Voice Studio"
      />
      <meta
        property="og:description"
        content=""
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://audio.moises.ai/moises-acqua.png"
      /> */}
    </Head>

    <Scripts />

    <div id="voice-studio-app">{children}</div>
  </>
)
