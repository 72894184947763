import { gql } from 'graphql-request'
import { ModelData } from '../../../store/models'

interface ModelsCli {
  getModels(): Promise<ModelData[]>
}

const Models = (graphQL: any): ModelsCli => {
  const getModels = async (): Promise<ModelData[]> => {
    const variables = {}
    const query = gql`
      query {
        voiceModels {
          id
          name
          identifier
          description
          tags
          updatedAt
          private
          metadata
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.voiceModels
  }

  return {
    getModels
  }
}

export default Models
